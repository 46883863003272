<script>
export default {
  props: ['inputData', 'inputContainerExternalClass'],
  data() {
    return {
      value: '',
    }
  },
  methods: {
    saveInput(title, from) {
      this.$store.commit('saveInput', { title, value: this.value, from })
      if (title === 'password')
        this.$emit('password', { title, value: this.value })
      else if (title === 'confirmPassword')
        this.$emit('password', { title, value: this.value })
    },
  },
}
</script>

<template>
  <ValidationProvider v-slot="{ errors, valid }" :rules="inputData.rules">
    <div class="FormInput " :class="inputContainerExternalClass">
      <label :for="inputData.heading" class="FormInput__inputContainer">
        <p class="FormInput__heading">
          <span>{{ inputData.heading }}</span>
          <span v-if="inputData.require" class="text-danger">*</span>
        </p>
        <input
          :id="inputData.heading" v-model="value" class="FormInput__input" :type="inputData.type"
          :state="errors[0] ? false : valid ? true : null"
          @blur="saveInput(inputData.title, inputData.from)"
        >
        <p class="text-secondary FormInput__input__error">{{ errors[0] }}</p>
      </label>
    </div>
  </ValidationProvider>
</template>

<style lang="scss" scoped>

</style>
