<script>
import Page1 from '@/components/pages/page1.vue'

export default { components: { Page1 } }
</script>

<template>
  <div>
    <Page1 />
  </div>
</template>

<style lang="scss" scoped>

</style>
