<script>
import { BFormSelect } from 'bootstrap-vue'

export default {
  components: { BFormSelect },
  props: ['inputData', 'inputContainerExternalClass'],
  data() {
    return {
      selected: null,
    }
  },

  methods: {
    saveInput(title, from) {
      this.$store.commit('saveInput', { title, value: this.selected, from })
      if (title === 'region')
        this.$store.commit('setCities', this.selected)
      else if (title === 'city')
        this.$store.commit('setZones', this.selected)
    },
  },
}
</script>

<template>
  <ValidationProvider v-slot="{ errors, valid }" :rules="inputData.rules">
    <div class="FormInput " :class="inputContainerExternalClass">
      <label :for="inputData.heading" class="FormInput__inputContainer">
        <p class="FormInput__heading">
          <span>{{ inputData.heading }}</span>
          <span v-if="inputData.require" class="text-danger">*</span>
        </p>
        <BFormSelect
          v-model="selected" :options="inputData.options"
          :state="errors[0] ? false : valid ? true : null" @change="saveInput(inputData.title, inputData.from)"
        />
        <p class="text-secondary FormInput__input__error">{{ errors[0] }}</p>
      </label>
    </div>
  </ValidationProvider>
</template>

<style lang="scss" scoped>

</style>
