<script>
import { ValidationObserver } from 'vee-validate'
import { BForm } from 'bootstrap-vue'
import UserIcon from '@/assets/svgs/User.vue'
import RadioBtnsContent from '@/components/ui/RadioBtnContent.vue'
import BottomRadioButtons from '@/components/ui/BottomRadioButtons.vue'
import Input from '@/components/ui/Input.vue'
import Button from '@/components/ui/Button.vue'
import Select from '@/components/ui/Select.vue'
import DatePicker from '@/components/ui/Datepicker.vue'
export default {
  components: {
    ValidationObserver,
    UserIcon,
    RadioBtnsContent,
    BottomRadioButtons,
    Input,
    Button,
    Select,
    DatePicker,
    BForm,
  },
  data() {
    return {
      heading: 'Renseignez les informations ci-dessous',
      submitted: false,
      showspinner: false,

      RadioData: [
        {
          id: 'aasa242425dasdafas',
          heading: 'Civilité',
          title: 'civility',
          from: 'page1',
          rquired: true,
          colClass: 'FormRadio__colSecond  page1__FormRadio__colSecond',
          RadioBtnMainContainerClass:
                        'FormRadio__second d-flex justify-content-between',
          rules: 'required',
          options: [
            {
              text: 'Madame',
              value: 'Mme.',
              class: 'Madame ',
            },
            {
              text: 'Monsieur',
              value: 'Mr.',
              class: 'Monsieur second',
            },
          ],
        },
      ],
      BottomRadioData: [
        {
          id: 'axc23fvc13',
          required: false,
          options: [
            {
              textContainerClass: 'FormRadio__btn__textContainer',
              text1class: 'text1',
              text2class: 'text2',
              text: 'Je souhaite recevoir la newsletter',
              text2:
                                '(promotions, nouveautés, promis nous ne faisons pas de spam)',
              value: 'newsletter',
              class: 'newsletter ',
            },
            {
              textContainerClass: 'FormRadio__btn__textContainer',
              text1class: 'text3',
              text: 'Je souhaite recevoir les conseils, les offres et les actualités de nos <br> partenaires sélectionnés',
              value: 'offers',
              class: 'offers',
            },
          ],
        },
      ],
      inputs: [
        {
          require: true,
          heading: 'Nom',
          type: 'text',
          component: 'Input',
          title: 'firstName',
          from: 'page1',
          rules: 'required',
        },
        {
          require: true,
          heading: 'Prénom',
          title: 'lastName',
          type: 'text',
          component: 'Input',
          from: 'page1',
          rules: 'required',
        },
        {
          require: true,
          heading: 'Date de naissance',
          type: 'datepicker',
          component: 'DatePicker',
          title: 'birthDate',
          from: 'page1',
          rules: 'required',
        },
        {
          require: true,
          heading: 'Addresse',
          type: 'text',
          component: 'Input',
          title: 'street',
          from: 'page1',
          rules: 'required',
        },
        {
          require: true,
          heading: 'Region',
          title: 'region',
          type: 'select',
          component: 'Select',
          from: 'page1',
          options: this.$store.state.regions,
          rules: 'required',
        },
        {
          require: true,
          heading: 'Ville',
          title: 'city',
          type: 'select',
          component: 'Select',
          from: 'page1',
          rules: 'required',
          options: [
            {
              value: null,
              text: 'Select City',
            },
          ],
        },

        {
          require: true,
          heading: 'Zone',
          title: 'zone',
          type: 'select',
          component: 'Select',
          from: 'page1',
          rules: 'required',
          options: [
            {
              value: null,
              text: 'Select Zone',
            },
          ],
        },

        {
          require: true,
          heading: 'Email',
          title: 'email',
          type: 'email',
          component: 'Input',
          from: 'page1',
          rules: 'required|email',
        },
        {
          require: true,
          heading: 'Téléphone',
          type: 'text',
          component: 'Input',
          title: 'phoneNumber',
          from: 'page1',
          rules: 'required|numeric',
        },
        {
          require: true,
          heading: 'Mot de passe',
          type: 'password',
          title: 'password',
          component: 'Input',
          from: 'page1',
          rules: 'required',
        },
        {
          require: true,
          heading: 'Confirmation mot de passe',
          type: 'password',
          title: 'confirmPassword',
          component: 'Input',
          from: 'page1',
          rules: 'required',
        },
      ],
      url: 'regions',
      message: '',
      password: '',
      confirmPassword: '',
    }
  },
  computed: {
    Regions() {
      return this.$store.state.regions
    },
  },

  watch: {
    '$store.state.status': function (message) {
      this.message = message
    },
    '$store.state.regions': function (regions) {
      const regionOptions = regions.map((region) => {
        return { value: region.id, text: region.name }
      })

      regionOptions.unshift({
        value: null,
        text: 'Select Region',
      })
      this.inputs = this.inputs.map((input) => {
        if (input.title === 'region')
          input.options = regionOptions

        return input
      })
    },
    '$store.state.cities': function (cities) {
      const cityOptions = cities.map((city) => {
        return { value: city.id, text: city.name }
      })

      cityOptions.unshift({
        value: null,
        text: 'Select City',
      })
      this.inputs = this.inputs.map((input) => {
        if (input.title === 'city')
          input.options = cityOptions

        return input
      })
    },
    '$store.state.zones': function (zones) {
      const zoneOptions = zones.map((zone) => {
        return { value: zone.id, text: zone.name }
      })
      zoneOptions.unshift({
        value: null,
        text: 'Select Zone',
      })
      this.inputs = this.inputs.map((input) => {
        if (input.title === 'zone')
          input.options = zoneOptions

        return input
      })
    },
  },
  mounted() {
    this.$store.commit('clearform1')
    this.$store.dispatch('setRegion', this.url)
  },
  methods: {
    async submitForm() {
      this.showspinner = true
      this.submitted = true
      if (this.password !== this.confirmPassword)
        return (this.message = 'Password Did not match')

      const res = await this.$store.dispatch('addCustomer', { url: 'customers' })
      if (res.status === 201) {
        this.showspinner = false
        this.$router.push({ path: '/authentication' })
      }
    },
    checkPass(password) {
      if (password.title === 'password')
        this.password = password.value
      else if ((password.title === 'confirmPassword'))
        this.confirmPassword = password.value
    },
  },
}
</script>

<template>
  <div class="page1__headerContainer">
    <div class="page1 page__bg">
      <div class="page1__container">
        <div class="page1__header text-center d-flex align-items-center">
          <div>
            <UserIcon />
          </div>
          <h1 class="text-uppercase flex-column page1__header__heading">
            {{ heading }}
          </h1>
        </div>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }" slim>
          <BForm ref="myform" @submit.prevent="handleSubmit(submitForm)">
            <div class="row">
              <div
                v-for="Radio in RadioData" :key="Radio.id" class="col-md-6 col-12"
                :class="Radio.colClass"
              >
                <RadioBtnsContent
                  flex-class="d-flex flex-sm-row flex-column align-items-sm-center page1__formRadiobtnContainer "
                  :radio-btn-main-container-class="Radio.RadioBtnMainContainerClass"
                  :heading="Radio.heading" :required="Radio.required" :options="Radio.options"
                  :title="Radio.title" :rules="Radio.rules" from="page1"
                />
              </div>
            </div>
            <div class="row page1__inputRow">
              <div v-for="input in inputs" :key="input.heading" class="col-md-6 col-12">
                <component :is="input.component" :input-data="input" @password="checkPass" />
              </div>
            </div>
            <div
              v-for="bottomRadio in BottomRadioData" :key="bottomRadio.id"
              class="page1__bottomRadioButtonsContainer"
            >
              <BottomRadioButtons :required="bottomRadio.required" :options="bottomRadio.options" />
              <p class="Form__required page1__required" :class="{ show: submitted }">
                {{ message }}
              </p>
            </div>
            <div class="text-center">
              <Button
                width="280.15px" height="38.58px" btn-text="Valider mes informations"
                external-class="page1__btn" :showspinner="showspinner" :disabled="showspinner"
              />
            </div>
          </BForm>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>

</style>
